import { render, staticRenderFns } from "./ComposerMenu.vue?vue&type=template&id=8b9f2e2c&scoped=true"
import script from "./ComposerMenu.vue?vue&type=script&lang=js"
export * from "./ComposerMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b9f2e2c",
  null
  
)

export default component.exports