import { render, staticRenderFns } from "./ImageMapWrap.vue?vue&type=template&id=9329cdd8&scoped=true"
import script from "./ImageMapWrap.vue?vue&type=script&lang=js"
export * from "./ImageMapWrap.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9329cdd8",
  null
  
)

export default component.exports